import React from "react";
import Layout from "../components/Layout";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useAuth } from "reactfire";
import "firebase/auth";

function ForgotPassword() {
    const {
        register,
        formState: { errors },
        handleSubmit,
      } = useForm();

      const auth = useAuth();
    

const onSubmit = (data, e) => {

    auth.sendPasswordResetEmail(data.email).then(function(){
        alert("A password link has been sent to your email, Please check.")
    }).catch(function(e){
        console.log(e);
    })
}
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <Layout>
      <section className="product_section">
        <Container>
          <div>
            <h1>Reset Password</h1>
            <hr />
          </div>
        </Container>
      </section>
      <section className="login_section">
        <Container>
          <Row>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
              <Form className="contact_form" onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <input
                    type="email"
                    name="email"
                    {...register("email", {
                      required: true,
                      pattern: { emailRegex },
                    })}
                    placeholder="Registered Email ID**"
                  />
                  {errors.email && errors.email.type === "required" && (
                    <p className="errorMsg">Email is required.</p>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <p className="errorMsg">
                      Please enter a valid email address.
                    </p>
                  )}
                </FormGroup>
                <Button className="send_btn" type="submit">Send Verification link</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}

export default ForgotPassword;
